@font-face {
  font-family: "Manuka";
  src: url("Manuka-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.manuka {
  font-family: "Manuka", sans-serif;
}
