@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts/fonts.css";

body {
  background-color: black;
}

.up {
  writing-mode: vertical-lr;
}

.hold {
  border-color: hsl(0, 100%, 61%);
  color: hsl(0, 100%, 61%);
}

.hold:hover {
  background-color: hsl(0, 100%, 61%);

  color: hsl(0, 0%, 0%);
}

.vert-marq p {
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
}

.vert-marq span {
  animation: marquee 8s linear infinite;
  display: inline-flex;
}

.vert-marq {
  top: 6em;
  position: relative;
  box-sizing: border-box;
  animation: vert 15s linear infinite;
}

.op {
  animation-direction: reverse !important;
}

/* Make it move! */
@keyframes vert {
  from {
    transform: translateY(-800%);
  }
  to {
    transform: translateY(-300%);
  }
}

.marquee {
  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 15s linear infinite;
  animation-play-state: paused;
}

.marquee .marquee__inner {
  animation-play-state: running;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.marquee__inner > div {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
}

.hold:hover .marquee__inner > div {
  transform: scaleX(0.9);
}

.hold:hover .marquee .marquee__inner,
.hold:hover .vert-marq {
  animation-play-state: paused;
}
